import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ua1, ua2, ua3, ua4, ua5, ua6, ua7, va1, va2, va3, va4, va5, va6, va7 } from '../../MediaConstant';
import Footer from '../../Reuseable Components/Footer';
import Header from '../../Reuseable Components/Header';
import HeaderInner from '../../Reuseable Components/HeaderInner';
import TopFooterCollage from '../../Reuseable Components/TopFooterCollage';
import Slider from 'react-slick/lib/slider';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const YourPower3c = () => {
    let navigate = useNavigate();

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className='power3-next'
                // style={{ ...style, display: "block", background: "red", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className="power3-prev"
                // style={{ ...style, display: "block", background: "green", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: dots => (
            <div
                style={{
                    // backgroundColor: "black",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px"
                }}
            >
                <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
            </div>
        ),
    };

    return (
        <div className="common_bg_back Your_Power ">
            <Header />
            <HeaderInner props={"Your Power"} />
            <div className='Your_Power_content'>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline">
                                <div class="lambi_line"></div>
                                <div class="flx_stepsboxes">
                                    <div className="box-wdth">
                                        <p class="bx-noactive">01</p>
                                        <p class="step1">Assesment</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">02</p>
                                        <p class="step1">Choose Your Power</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-active">03</p>
                                        <p class="step1">Select Your Plan</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">04</p>
                                        <p class="step1">Checkout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline pos-rel pt-3">
                                <Slider {...settings} className="slider_top_home">
                                    <div className='actv_brwn'>
                                        <p className='mb-0'>
                                            Fri
                                        </p>
                                        <p>
                                            Dec 10
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Sat
                                        </p>
                                        <p>
                                            Dec 11
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Sun
                                        </p>
                                        <p>
                                            Dec 12
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Mon
                                        </p>
                                        <p>
                                            Dec 13
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Tues
                                        </p>
                                        <p>
                                            Dec 14
                                        </p>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gry_sec_power'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p className='menu_for_txt mb-0'>Menu for Fri, 10 Dec</p>
                                <p className='menu_for_box'>Add to Box</p>
                            </div>
                            <div className='col-lg-6 text-right'>
                                <button className='btn btn-light bt-brwn px-5'>SAVE</button>
                            </div>
                            <div className='col-lg-12 mb-0'>
                                <div className='line_gry_light mb-3'>

                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={va1} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Spicy Oat Porridge</p>
                                <p className='top_cont_crd'>Ginger / chia seeds / spice mix / banana / walnuts</p>
                                <p className='top_heading_crd'>$8.50</p>
                                <div>
                                    <div className='del_btn_pow'>
                                        <span className='del_ico'>
                                            <DeleteSweepIcon />
                                        </span>
                                        <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4 '>
                                <img src={va2} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Parsnip Dip</p>
                                <p className='top_cont_crd'>Parsnip / garlic / onion / route / nutmeg / sage infused oil</p>
                                <p className='top_heading_crd'>$ 5.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={va3} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Mac & Cheese </p>
                                <p className='top_cont_crd'>Parmesan cheese / cream cheese /
                                    cauliflower sauce</p>
                                <p className='top_heading_crd'>$ 11.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={va4} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Basil
                                    Lemonade</p>
                                <p className='top_cont_crd'>The ancient flower that refreshes the palates</p>
                                <p className='top_heading_crd'>$ 4.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={va5} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Adani Chai “Rich &
                                    Perfect</p>
                                <p className='top_cont_crd'>Rich and spicy with cinnamon / clove / cardamom / milk served with butter cookie</p>
                                <p className='top_heading_crd'>$ 6.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={va6} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Semolina
                                    Cake</p>
                                <p className='top_cont_crd'>Pumpkin seed / rose syrup /
                                    creme fresh</p>
                                <p className='top_heading_crd'>$ 5.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>

                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={va7} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Green Lentil & Walnut
                                    Salad Wrap</p>
                                <p className='top_cont_crd'>Brown lentils / roasted pepper / garlic / parsley / spinach / spring onion / walnut</p>
                                <p className='top_heading_crd'>$ 16.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TopFooterCollage />
                <Footer />
            </div>
        </div>
    )
};

export default YourPower3c;