import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
// import { toast } from "react-toastify";
const stripePromise = loadStripe(
  "pk_test_51L7zHyFqIiZq0dEpc0PLbRjukbs86xsyHAIfdWQ5tFgpbN24TXDj0wuX5pEK7U53wrqotJoxmwtBfXvyGlHSEpyR00niD3uArJ"
);
//

const CheckoutForm = (props) => {
//   const { StepTwoContinue, handleNext, setCardToken, cardToken } = props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
//   const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    // setCardToken(payload?.token?.id);
    console.log("check", payload);
    if (payload?.token?.id) {
    //   handleNext(event);
      return;
    } else {
    //   toast.error("Something Went Wrong");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />
      
        <ul className="list-inline">
          <li>
            <button
              type="submit"
              style={{background:"#58360B" , color:"#fff" , width:"100%", padding:"1rem",}}
              className="default-btn stripePay-Btn btn f-14 f-pop-med"
              disabled={!stripe || !elements || paybutton}
              onClick={props.handleClickOpen}
            >
                Proceed
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

const StripeForm = (props) => {
//   const {   } = props;
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm  handleClickOpen={props.handleClickOpen}
      />

    </Elements>
  );
};

export default StripeForm;