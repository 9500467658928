import React, { useEffect } from "react";
import Footer from "../../Reuseable Components/Footer";
import Header from "../../Reuseable Components/Header";
import HomeBanner from "./HomeBanner";
import '../../Assests/Css/Home.css';
import '../../Assests/Css/Common.css';
import DietCoach from "./DietCoach";
import LatestVideo from "./LatestVideo";
import TopFooterCollage from "../../Reuseable Components/TopFooterCollage";
import AOS from 'aos';

const HomePage = () => {
    useEffect(() => {
        setTimeout(() => {
          AOS.init();
        }, 1000);
      }, [])
    return (
        <div className="HomePage">
            <Header />
            {/* banner section */}
            <HomeBanner />
            {/* banner section */}

            {/* diet coach */}
            <DietCoach />
            {/* diet coach */}

            {/* Latest Video */}
            <LatestVideo />
            {/* Latest Video */}

            <TopFooterCollage />

            <Footer />
        </div>
    )
}

export default HomePage;