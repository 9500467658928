import React, { useEffect } from "react";
import { foodCart1, foodCart2, homeBanner, HomeBannerImg, homex1, homex2 ,celebration ,cooking_workshop ,homebanner1,bannerimg1 } from "../../MediaConstant";
import TypeAnimation from "react-type-animation";
import AOS from 'aos';
import 'animate.css'
import WOW from 'wowjs';

const HomeBanner = () => {
    useEffect(() => {
        setTimeout(() => {
          AOS.init();
        }, 1000);
        new WOW.WOW({
            live: false
          }).init();
      }, [])
    return (
        <div className="HomeBannerPage">
            <div style={{ background: `linear-gradient(45deg, #00000066, transparent),url("${bannerimg1}")` }} className="bannerImage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 my-5 ptpb-cus">
                        <TypeAnimation
                    cursor={false}
                    sequence={["Food Is Power", 3000, ""]}
                    wrapper="span"
                    className="HomeBannerHeading"
                    repeat={Infinity}
                  />
                  <span className="HomeBannerHeading clr-trxsprt">A</span>
                            {/* <p className="HomeBannerHeading"> Cooking Workshops</p> */}
                            <p className="HomeBannerSubHeading">Food as power
                            {/* , Not a poison  */}
                            </p>
                           

                            <button className="btn btn-light bt-brwn round-5 ">Get Started</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container ">
                <div className="row my-exp-cuss">
                    <div className="col-lg-6">
                        <div className="bx-share_txt wow animate__animated animate__fadeInDown">
                            <p className="share_txt">Sharing Our<br /> Experience with you </p>
                        </div>
                    </div>
                    <div className="col-lg-6"
                        data-aos="fade-up-left"
                    >
                        <div className="row">
                            <div className="col-lg-6 ">
                                {/* <img className="img-homebann-exp" src={foodCart1} /> */}
                                <img className="img-homebann-exp" src={cooking_workshop} />
                                <div className="crd-bdy-exp text-center">
                                    <p className="crd-bdy-exp-mainhead"> Cooking Workshops</p>
                                    <p className="crd-bdy-exp-subhead">For your body & mind </p>
                                    <p className="crd-bdy-exp-Exploe">Explore</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className="img-homebann-exp" src={celebration} />
                                <div className="crd-bdy-exp text-center">
                                    <p className="crd-bdy-exp-mainhead">Wellness Chef 
Experience</p>
                                    <p className="crd-bdy-exp-subhead">For your body & mind </p>
                                    <p className="crd-bdy-exp-Exploe">Explore</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner;