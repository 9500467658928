import React from "react";
import Footer from "../../Reuseable Components/Footer";
import Header from "../../Reuseable Components/Header";
import HeaderInner from "../../Reuseable Components/HeaderInner";
import TopFooterCollage from "../../Reuseable Components/TopFooterCollage";
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import ProfileTabs from "./ProfileTabs";

const MyAccount = () => {
    return (
        <div className="MyAccount common_bg_back">
            <Header />
            <HeaderInner props={"My Account"} />
            <div className="OurRecipes_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 pt-5 my-5">
                            <ProfileTabs />
                        </div>
                        <div className="col-lg-9">
                            <div className="col-lg-12 mt-5">
                                <p className="profile_heading mb-4">Account Info</p>
                            </div>
                            <div className="gry_div_prof my-4">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h2>Personal Info</h2>
                                        <p>John Doe</p>
                                        <p>Email: Johndoe@gmail.com</p>
                                        <p>Customer: 0123 456 789</p>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <p className="mr-4">Edit</p>
                                    </div>
                                </div>
                            </div>
                            <div className="gry_div_prof my-4">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h2>Password</h2>
                                        <p>Current Password</p>
                                        <p>....</p>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <p className="mr-4">Edit</p>
                                    </div>
                                </div>
                            </div>
                            <div className="gry_div_prof my-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>Your credit</h2>
                                        <p>You have $0 at the moment</p>
                                        <div className="brdr-btm-line"></div>
                                    </div>
                                    <div className="col-lg-8">
                                        <p><TollOutlinedIcon /> Give $0, Get $0</p>
                                        <p>You $0 in credit for each other friend that you refer</p>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <button className="btn btn-light bt-brwn-brdrrd1">
                                            Give $0
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="facebook_div my-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>Connect to facebook</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <p>Connect your Facebook account to easily log in
                                            to (name) using your Facebook Details.</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <button className="btn btn-light fb-btn"> <FacebookIcon /> Connect to Facebook </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TopFooterCollage />
                <Footer />
            </div>
        </div>
    )
}

export default MyAccount;