import React from 'react';
import { useNavigate } from 'react-router-dom';
import { manu1, manu2, manu3, manu4, powm1, powm2, powm3, powm4, powm5, powm6, powm7 } from '../../MediaConstant';
import Footer from '../../Reuseable Components/Footer';
import Header from '../../Reuseable Components/Header';
import HeaderInner from '../../Reuseable Components/HeaderInner';
import TopFooterCollage from '../../Reuseable Components/TopFooterCollage';
import Slider from 'react-slick/lib/slider';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const YourPower3 = () => {
    let navigate = useNavigate();

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className='power3-next'
                // style={{ ...style, display: "block", background: "red", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className="power3-prev"
                // style={{ ...style, display: "block", background: "green", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: dots => (
            <div
                style={{
                    // backgroundColor: "black",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px"
                }}
            >
                <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
            </div>
        ),
    };

    return (
        <div className="common_bg_back Your_Power ">
            <Header />
            <HeaderInner props={"Your Power"} />
            <div className='Your_Power_content'>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline">
                                <div class="lambi_line"></div>
                                <div class="flx_stepsboxes">
                                    <div className="box-wdth">
                                        <p class="bx-noactive">01</p>
                                        <p class="step1">Assesment</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">02</p>
                                        <p class="step1">Choose Your Power</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-active">03</p>
                                        <p class="step1">Select Your Plan</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">04</p>
                                        <p class="step1">Checkout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline pos-rel pt-3">
                                <Slider {...settings} className="slider_top_home">
                                    <div className='actv_brwn'>
                                        <p className='mb-0'>
                                            Fri
                                        </p>
                                        <p>
                                            Dec 10
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Sat
                                        </p>
                                        <p>
                                            Dec 11
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Sun
                                        </p>
                                        <p>
                                            Dec 12
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Mon
                                        </p>
                                        <p>
                                            Dec 13
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Tues
                                        </p>
                                        <p>
                                            Dec 14
                                        </p>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gry_sec_power'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p className='menu_for_txt mb-0'>Menu for Fri, 10 Dec</p>
                                <p className='menu_for_box'>Add to Box</p>
                            </div>
                            <div className='col-lg-6 text-right'>
                                <button onClick={()=>{navigate('/yourpower4')}} className='btn btn-light bt-brwn px-5'>SAVE</button>
                            </div>
                            <div className='col-lg-12 mb-0'>
                                <div className='line_gry_light mb-3'>

                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={powm1} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Baked Fava Beans</p>
                                <p className='top_cont_crd'>Feta cheese / cucumber /
                                    bell pepper / herbs / butterflap bread</p>
                                <p className='top_heading_crd'>$13.50</p>
                                <div>
                                    <button className='btn btn-light bt-light-brdrrd1 px-5 w-btn-pow show_other_pow1'>Add to Box</button>
                                    {/* <div className='del_btn_pow'>
                                        <span className='del_ico'>
                                            <DeleteSweepIcon />
                                        </span>
                                        <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4 '>
                                <img src={powm2} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Cauliflower & Caraway</p>
                                <p className='top_cont_crd'>Cauliflower / herb soft cheese / cumin caraway / oregano infused oil</p>
                                <p className='top_heading_crd'>$ 5.50</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={powm3} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Thanksgiving Roll</p>
                                <p className='top_cont_crd'>Cream cheese stuffing / cranberry
                                    <br /> pomegranate sauce</p>
                                <p className='top_heading_crd'>$ 13.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={powm4} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Sage & Wild Orange
                                    Tamarind</p>
                                <p className='top_cont_crd'>Excellent for stress reliever & focus</p>
                                <p className='top_heading_crd'>$ 13.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={powm5} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Turkish Coffee “Frothy & 
Creamy</p>
                                <p className='top_cont_crd'>Served with water / turkish delight</p>
                                <p className='top_heading_crd'>$ 13.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={powm6} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Matcha 
Cake</p>
                                <p className='top_cont_crd'>Almond flour / matcha / cream cheese / diet molasses</p>
                                <p className='top_heading_crd'>$ 13.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>

                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={powm7} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Watermelon Salad</p>
                                <p className='top_cont_crd'>Mint / feta cheese / pumpkin pesto /
peppermint / oilfusion</p>
                                <p className='top_heading_crd'>$ 13.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TopFooterCollage />
                <Footer />
            </div>
        </div>
    )
};

export default YourPower3;