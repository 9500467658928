import React from "react";
import { useNavigate } from "react-router-dom";
import { Recipie_img } from "../../../MediaConstant";
import Footer from "../../../Reuseable Components/Footer";
import Header from "../../../Reuseable Components/Header";
import HeaderInner from "../../../Reuseable Components/HeaderInner";
import RecepieFilters from "../../../Reuseable Components/RecepieFilters";
import TopFooterCollage from "../../../Reuseable Components/TopFooterCollage";

const OurRecepies = () => {
    const navigate = useNavigate()
    return (
        <div className="OurRecepies common_bg_back">
            <Header />
            <HeaderInner props={"Cooking Workshops"} />
            <div className="OurRecipes_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 my-5">
                            <RecepieFilters />
                        </div>
                        <div className="col-lg-9">
                            <div className="row mb-5">
                                {[0, 1, 2, 3, 4, 5].map((item, index) => {
                                    if (index % 2 === 0) {
                                        return (
                                            <div key={index} className="col-lg-6 pos-rel my-3 text-center">
                                                <div className="line_right"></div>
                                                <div className="meal_crd">
                                                    <img className="w-100" src={Recipie_img} />
                                                    <p className="time_real">20 Min Real</p>
                                                    <p className="px-4 rec_desscc">Lorem ipsum dolor sit amet, consetetur.Lorem ipsum dolor sit amet, consetetur.</p>
                                                    <button className="btn btn-light bt-brwn mt-4"
                                                        onClick={()=>navigate('/recipe-details')}
                                                    >View Recipe</button>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={index} className="col-lg-6 pos-rel my-3 text-center">
                                                <div className="meal_crd">
                                                    <img className="w-100" src={Recipie_img} />
                                                    <p className="time_real">20 Min Real</p>
                                                    <p className="px-4 rec_desscc">Lorem ipsum dolor sit amet, consetetur.Lorem ipsum dolor sit amet, consetetur.</p>
                                                    <button className="btn btn-light bt-brwn mt-4"
                                                         onClick={()=>navigate('/recipe-details')}
                                                    >View Recipe</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TopFooterCollage />
            <Footer />
        </div>
    )
}

export default OurRecepies;