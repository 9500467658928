import React from "react";
import Slider from "react-slick";
import { food1, food2, food3 } from "../MediaConstant";

function BroadSlider(props) {

  console.log(props.content);

  function SampleNextArrow2(props) {
    const { style, onClick } = props;
    return (
      <div
        className='Slider_top_custom_arrows-broad-next'
        // style={{ ...style, display: "block", background: "red", height: "80px", width: "80px" }}
        onClick={onClick}
      />
    );
  }


  function SamplePrevArrow2(props) {
    const { style, onClick } = props;
    return (
      <div
        className="Slider_top_custom_arrows-broad-prev"
        // style={{ ...style, display: "block", background: "green", height: "80px", width: "80px" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };

  const data = [
    {
      id: 1,
      img: food1,
    },
    {
      id: 2,
      img: food2,
    },
    {
      id: 3,
      img: food3,
    },
    {
      id: 4,
      img: food2,
    },
    {
      id: 5,
      img: food1,
    },
  ];
  return (
    <div>
      <Slider {...settings}>
        {props.content?.map((item, index) => {
          return (
            <div className="slider-card" key={index}>
              <div>
                <img src={item?.img} alt="" className="w-100" />
              </div>
              <div className="content">
                <p className="title">{item?.head}</p>
                <p className="tags">*{item?.category}</p>
              </div>
              <p className="details">
                {item?.para}
              </p>
              <p className="price">{item?.Price}</p>
              {/* <p className="price">$ 13.50</p> */}
              <button className="btn Mealbtn">Select Meals</button>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default BroadSlider;