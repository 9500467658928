import React from 'react';
import { useNavigate } from 'react-router-dom';
import { manu1, manu2, manu3, manu4 } from '../../MediaConstant';
import Footer from '../../Reuseable Components/Footer';
import Header from '../../Reuseable Components/Header';
import HeaderInner from '../../Reuseable Components/HeaderInner';
import TopFooterCollage from '../../Reuseable Components/TopFooterCollage';

const YourPower2 = () => {
    let navigate = useNavigate();
    return (
        <div className="common_bg_back Your_Power ">
            <Header />
            <HeaderInner props={"Your Power"} />
            <div className='Your_Power_content'>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline">
                                <div class="lambi_line"></div>
                                <div class="flx_stepsboxes">
                                    <div className="box-wdth">
                                        <p class="bx-noactive">01</p>
                                        <p class="step1">Assesment</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-active">02</p>
                                        <p class="step1">Choose Your Power</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">03</p>
                                        <p class="step1">Select Your Plan</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">04</p>
                                        <p class="step1">Checkout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='whatsmenu'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 text-center'>
                                <p className='menuhead your_p_Asses mb-0'>Whats your Power (Blood Type)</p>
                                <p className='del'> Delivery Services</p>
                            </div>
                            <div className='col-lg-3 my-5'>
                                <div className='crd_menu'>
                                    <img className='w-100' src={manu1} alt="recepie menu" />
                                    <p className='menu_crd_hd'>Keto</p>
                                    <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                    <p className='menu_dr-p'>2 Beverages</p>
                                    <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                    <div className='d-flex'>
                                        <button onClick={()=>{navigate('/YourPower3a')}} className='btn btn-light mr-3 bt-brwn-brdrrd1 px-4'>Selected</button>
                                        {/* <button className='btn btn-light bt-light-brdrrd1'>View Recipe</button> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 my-5'>
                                <div className='crd_menu'>
                                    <img className='w-100' src={manu2} alt="recepie menu" />
                                    <p className='menu_crd_hd'>Mediterranean</p>
                                    <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                    <p className='menu_dr-p'>2 Beverages</p>
                                    <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                    <div className='d-flex'>
                                        {/* <button className='btn btn-light mr-3 bt-brwn-brdrrd1'>View Plan</button> */}
                                        <button onClick={()=>{navigate('/YourPower3')}} className='btn btn-light bt-light-brdrrd1 px-4'>Select</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 my-5'>
                                <div className='crd_menu'>
                                    <img className='w-100' src={manu3} alt="recepie menu" />
                                    <p className='menu_crd_hd'>Nomad</p>
                                    <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                    <p className='menu_dr-p'>2 Beverages</p>
                                    <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                    <div className='d-flex'>
                                        {/* <button className='btn btn-light mr-3 bt-brwn-brdrrd1'>View Plan</button> */}
                                        <button onClick={()=>{navigate('/YourPower3b')}} className='btn btn-light bt-light-brdrrd1 px-4'>Select</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 my-5'>
                                <div className='crd_menu'>
                                    <img className='w-100' src={manu4} alt="recepie menu" />
                                    <p className='menu_crd_hd'>Fusion</p>
                                    <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                    <p className='menu_dr-p'>2 Beverages</p>
                                    <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                    <div className='d-flex'>
                                        {/* <button className='btn btn-light mr-3 bt-brwn-brdrrd1'>View Plan</button> */}
                                        <button onClick={()=>{navigate('/YourPower3c')}} className='btn btn-light bt-light-brdrrd1 px-4'>Select</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TopFooterCollage />
            <Footer />
        </div>
    )
};

export default YourPower2;