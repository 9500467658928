import React from "react";
import { auth_img, ico_auth1, ico_auth2, ico_auth3 } from "../../MediaConstant";
import Footer from "../../Reuseable Components/Footer";
import Header from "../../Reuseable Components/Header";
import HeaderInner from "../../Reuseable Components/HeaderInner";
import '../../Assests/Css/Auth.css';
// import '../../Assests/Css/Common.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Signup = () => {
    return (
        <div className="Login">
            <div className="common_bg_back Auth">
                <Header />
                <HeaderInner props={"Sign Up"} />
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <img className="w-100" src={auth_img} alt="food" />
                        </div>
                        <div className="col-lg-6">
                            <div className="Auth_box text-center">
                                <div className="main-px-mx">
                                    <p className="main_heading">Create Your Account</p>
                                    <p className="main_sub">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                                </div>
                                <div>
                                    <input placeholder="Username" className="input_fld" type='text' />
                                </div>
                                <div>
                                    <input placeholder="Email" className="input_fld" type='email' />
                                </div>
                                <div>
                                    <input placeholder="Phone" className="input_fld" type='tel' />
                                </div>
                                <div className="pos-rel">
                                    <RemoveRedEyeIcon className="eye_set" />
                                    <input placeholder="Password" className="input_fld" type='password' />
                                </div>
                                <div className="pos-rel">
                                    <RemoveRedEyeIcon className="eye_set" />
                                    <input placeholder="Confirm Password" className="input_fld" type='password' />
                                </div>
                                 <div>
                                    <button className="btn btn-light bt-brwn w-100 mt-2 mb-3">Sign Up</button>
                                </div>
                                {/*<div>
                                    <p className="dont mb-0">Don't have account? <span className="brwnclr">Sign Up</span></p>
                                </div>
                                <div className="mb-3">
                                    <div className="line-main">
                                        <div className="line1">
                                        </div>
                                        <div className="or">
                                            Or
                                        </div>
                                        <div className="line2">
                                        </div>
                                    </div>
                                </div>
                                <div className="pos-rel mt-4">
                                    <img className="img_goog" src={ico_auth1} alt="auth ico" />
                                    <button className="btn btn-light btn-goog">Sign up with Google</button>
                                </div>
                                <div className="pos-rel mt-3">
                                    <img className="img_fb" src={ico_auth2} alt="auth ico" />
                                    <button className="btn btn-ligh btn-fb">Sign up with facebook</button>
                                </div>
                                <div className="pos-rel mt-3">
                                <img className="img_appl" src={ico_auth3} alt="auth ico" />
                                    <button className="btn btn-light  btn-appl">Sign up with Apple</button>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Signup;