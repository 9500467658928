import React from "react";
import { gallery0, gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery7,keto,market,meditarr,nomad,fusion,experience,ketonew,womenworkoutnew,mediterraneannew,giveback,heathcoach,nomadnew,fusionnew } from "../MediaConstant";

const TopFooterCollage = () => {
    return (
        <div className="TopFooterCollage">
            <div className="flx-1-cont">
                <div className="row">
                    <div className="col-lg-6 p-0">
                        <div style={{ backgroundImage: `url("${ketonew}")` }} className="gall_img">
                            <p>Keto</p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0">
                        <div style={{ backgroundImage: `url("${mediterraneannew}")` }} className="gall_img">
                            <p>Mediterranean</p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0">
                        <div style={{ backgroundImage: `url("${nomadnew}")` }} className="gall_img">
                            <p>Nomad</p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0">
                        <div style={{ backgroundImage: `url("${fusionnew}")` }} className="gall_img">
                            <p>Fusion</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flx-2-cont text-center wow animate__animated animate__fadeInDown">
                {/* <p className="font_Photogallery_head">Photo gallery</p> */}
                <p className="font_Photogallery_subhead mb-5">Food is Power
                {/* , Not Is Poison */}
                </p>
                <button className="btn btn-light bt-blk bt-fit">OUR INSTAGRAM</button>
            </div>
            <div className="flx-3-cont">
                <div className="row">
                    <div className="col-lg-6 p-0 pos-rel">
                        <div style={{ backgroundImage: `url("${experience}")` }} className="gall_img">
                            <p>Experience</p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0 pos-rel">
                        <div style={{ backgroundImage: `url("${market}")` }} className="gall_img">
                            <p>The Market</p>
                        </div>

                    </div>
                    <div className="col-lg-6 p-0 pos-rel">
                        <div style={{ backgroundImage: `url("${giveback}")` }} className="gall_img">
                            <p> Giving Back</p>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0 ">
                        <div style={{ backgroundImage: `url("${womenworkoutnew}")` }} className="gall_img pos-rel">
                            <p>Health Coaching</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopFooterCollage;