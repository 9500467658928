import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth_img, va1, va2, va3, va4, va5, va6, va7 } from '../../MediaConstant';
import Footer from '../../Reuseable Components/Footer';
import Header from '../../Reuseable Components/Header';
import HeaderInner from '../../Reuseable Components/HeaderInner';
import TopFooterCollage from '../../Reuseable Components/TopFooterCollage';
import Slider from 'react-slick/lib/slider';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import StripeForm from '../../Reuseable Components/StripeForm';

const YourPower4 = () => {
    const handleClickOpen = () => {
        console.log("working");
    }
    return (
        <div className="common_bg_back Your_Power ">
            <Header />
            <HeaderInner props={"Your Power"} />
            <div className='Your_Power_content'>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline">
                                <div class="lambi_line"></div>
                                <div class="flx_stepsboxes">
                                    <div className="box-wdth">
                                        <p class="bx-noactive">01</p>
                                        <p class="step1">Assesment</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">02</p>
                                        <p class="step1">Choose Your Power</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">03</p>
                                        <p class="step1">Select Your Plan</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-active">04</p>
                                        <p class="step1">Checkout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='crd_add_home_address_box bg-white'>
                                <p className='crd_add_home_address_had'>Add Your Home Address</p>
                                <div className='row'>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="First Name" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="Last Name" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="Street Address" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="City" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-4 my-2'>
                                        <input type="text" placeholder="Age" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-4 my-2'>
                                        <input type="text" placeholder="Suite" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-4 my-2'>
                                        <input type="text" placeholder="Floor" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="Phone Number" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="Email Address" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="Zip Code" class="inpt-fld" />
                                    </div>
                                    <div className='col-lg-6 my-2'>
                                        <input type="text" placeholder="State" class="inpt-fld" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <img className="w-100" src={auth_img} alt="food" />
                        </div>
                        <div className='col-lg-7'>
                            <div className='crd_add_home_address_box bg-trans'>
                                <p className='crd_add_home_address_had'>Enter Card Details</p>
                                <p className='crd_add_home_address_loretxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp</p>

                                <div className='row'>
                                    <div className='col-lg-12 my-2'>
                                        <StripeForm handleClickOpen={handleClickOpen} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 mb-5 mt-3'>
                            <div className='Order_summary_box mb-5 '>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <p className='crd_add_home_address_had'>Order Summary</p>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='Order_summary_box_p'>Veggie and Calorie Smart plan</p>
                                    </div>
                                    <div className='col-lg-6 text-right'>
                                        <p className='Order_summary_box_p'>$179.76</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <p className='Order_summary_box_p gr_bx_t'>6 recipes per week for 4 people (24 servings total)</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='brdr-btm-w100'>

                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='Order_summary_box_p'>Shipping Charges</p>
                                    </div>
                                    <div className='col-lg-6 text-right'>
                                        <p className='Order_summary_box_p'>$9.99</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='brdr-btm-w100'>

                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='Order_summary_box_p'>Your Total Cost is</p>
                                    </div>
                                    <div className='col-lg-6 text-right'>
                                        <p className='Order_summary_box_p'>$189.75</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <p className='crd_add_home_address_had'>Delivery summary</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <p className='Order_summary_box_p'>Delivery instructions</p>
                                    </div>
                                    <div className='col-lg-6'>
                                        <p className='Order_summary_box_p gr_bx_t'>First delivery</p>
                                    </div>
                                    <div className='col-lg-6 text-right'>
                                        <p className='Order_summary_box_p underlinez'>Edit</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='brdr-btm-w100'>

                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <p className='Order_summary_box_p '>Friday, December 17</p>
                                    </div>
                                    <div className='col-lg-12'>
                                        <p className='Order_summary_box_p gr_bx_t mb-0'>You will get your box every Thursday</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <TopFooterCollage />
                <Footer />
            </div>
        </div>
    )
};

export default YourPower4;