import React from "react";
import { fb, insta, logo, twitter, youtube } from "../MediaConstant";
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import '../Assests/Css/Header.css';
import SwipHeader from './SwipHeader';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    let navigate = useNavigate();
    let currentUrl = window.location.href.split("/");
    console.log(currentUrl);

    let headerLinks = [
        { name: "Home", link: "/", },
        { name: "About Us", link: "/about", },
        { name: "Your Power", link: "/yourpower", },
        { name: "Broad Street Markeet", link: "/broad-street-market", },
        { name: "Giving Back", link: "/giving-back", },
        { name: "Contact Us", link: "/contact", },
    ];

    let social_links = [
        { img_1: twitter },
        { img_1: fb },
        { img_1: insta },
        { img_1: youtube },
    ]

    let headerLinks_props = { social_links : social_links , headerLinks : headerLinks}

    return (
        <>
            <div className="Header header-full">
                <div className="bg-white bg-tp-hdr mb-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <img src={logo} width={140} />
                            </div>
                            <div className="col-lg-5 text-right">
                                <div className="input-div-bx">
                                    <div className="d-flex justify-center">
                                        <input type='text' placeholder="SEARCH..." className="header_search_fld" />
                                        <div className="brwn_bx"><SearchIcon /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ico_div_box">
                                    <div className="d-flex brownclr">
                                        <ShoppingCartOutlinedIcon className="icobrown" />
                                        <p>My cart</p>
                                    </div>
                                    <div className="d-flex brownclr" onClick={() => { navigate('/profile') }}>
                                        <PermIdentityOutlinedIcon className="icobrown" />
                                        <p className={`${currentUrl?.[3] === "profile" ? "active" : null}`}>My Account</p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex brownclr">
                                            <p onClick={() => { navigate('/login') }}>Login</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white bg-btm-hdr">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="linksection_div">
                                    <div className="links_divs">
                                        <div className="link_single_div pos-rel">
                                            <p onClick={() => { navigate('/') }}
                                                className={`${currentUrl?.[3] === "" ? "active header_p  cursor_pointer" : "header_p  cursor_pointer"}`}
                                            >Home</p>
                                            <div className="brdr-right-line"></div>
                                            {currentUrl?.[3] === "" ? <div className="dot_headerzz">.</div> : <></>}
                                        </div>
                                        <div className="link_single_div pos-rel">
                                            <p onClick={() => { navigate('/about') }}
                                                className={`${currentUrl?.[3] === "about" ? "active header_p  cursor_pointer" : "header_p  cursor_pointer"}`}
                                            >About Us</p>
                                            <div className="brdr-right-line"></div>
                                            {currentUrl?.[3] === "about" ? <div className="dot_headerzz">.</div> : <></>}
                                        </div>
                                        <div className="link_single_div pos-rel">
                                            <p onClick={() => { navigate('/yourpower') }}
                                                className={`${currentUrl?.[3] === "yourpower" ? "active header_p  cursor_pointer" : "header_p  cursor_pointer"}`}
                                            >Your Power</p>
                                            <div className="brdr-right-line"></div>
                                            {currentUrl?.[3] === "yourpower" ? <div className="dot_headerzz">.</div> : <></>}

                                        </div>
                                        <div className="link_single_div pos-rel">
                                            <p onClick={() => { navigate('/broad-street-market') }}
                                                className={`${currentUrl?.[3] === "broad-street-market" ? "active header_p  cursor_pointer" : "header_p  cursor_pointer"}`}
                                            >Broad Street Market</p>
                                            <div className="brdr-right-line"></div>
                                            {currentUrl?.[3] === "broad-street-market" ? <div className="dot_headerzz">.</div> : <></>}
                                        </div>
                                        <div className="link_single_div pos-rel">
                                            <p onClick={() => { navigate('/giving-back') }}
                                                className={`${currentUrl?.[3] === "giving-back" ? "active header_p  cursor_pointer" : "header_p  cursor_pointer"}`}
                                            >Giving Back</p>
                                            <div className="brdr-right-line"></div>
                                            {currentUrl?.[3] === "giving-back" ? <div className="dot_headerzz">.</div> : <></>}
                                        </div>

                                        <div className="link_single_div pos-rel">
                                            <p onClick={() => { navigate('/contact') }}
                                                className={`${currentUrl?.[3] === "contact" ? "active header_p  cursor_pointer" : "header_p  cursor_pointer"}`}
                                            >Contact Us</p>
                                            {currentUrl?.[3] === "contact" ? <div className="dot_headerzz dot_headerzz_last">.</div> : <></>}
                                        </div>
                                    </div>
                                    <div className="icons_divs">
                                        <img className="mr-3" width={30} height={30} src={twitter} />
                                        <img className="mr-3" width={30} height={30} src={fb} />
                                        <img className="mr-3" width={30} height={30} src={insta} />
                                        <img width={30} height={30} src={youtube} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* responsive header starts here */}
            <div className='header-small'>
                <div className='bg-red-hdr'>
                    <div className='header-small'>
                        <SwipHeader headerLinks={headerLinks_props} />
                    </div>
                    <div className='d-flex'>
                        <div>
                            <Link className='f-pop-reg home_link mr-2' to="/login">
                                <PersonIcon className='align-self-center text-white mt-2 me-4' />
                            </Link>
                        </div>
                        <div className=''>
                            <Link to="/">
                                <img onClick={() => { navigate('/') }} className=' img_respon py-2 px-0' src={logo} alt='logo' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;
