import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import AboutUs from '../Pages/AboutUs/AboutUs';
import Login from '../Pages/Authentication/Login';
import Signup from '../Pages/Authentication/Signup';
import BroadStreetMarkeet from '../Pages/BroadStreetMarkeet/BroadStreetMarkeet';
import Contact from '../Pages/ContactUs/ContactUs';
import GivingBack from '../Pages/GivingBack/GivingBack';
import HomePage from '../Pages/HomePage/HomePage';
import OurRecepies from '../Pages/HomePage/OurRecepies/OurRecepies';
import RecipeDetails from '../Pages/HomePage/OurRecepies/RecipeDetails';

import MyAccount from '../Pages/ProfilePages/MyAccount';
import YourPower from '../Pages/YourPower/YourPower';
import YourPower2 from '../Pages/YourPower/YourPower2';
import YourPower3 from '../Pages/YourPower/YourPower3';
import YourPower3a from '../Pages/YourPower/YourPower3a';
import YourPower3b from '../Pages/YourPower/YourPower3b';
import YourPower3c from '../Pages/YourPower/YourPower3c';
import YourPower4 from '../Pages/YourPower/YourPower4';


const AllRoutes = () => {
    return (
        <div className='AllRoutes'>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />     
                    <Route path="/contact" element={<Contact />} /> 
                    <Route path="/menu" element={<OurRecepies />} />     
                    <Route path="/recipe-details" element={<RecipeDetails />} />     
                    <Route path="/profile" element={<MyAccount />} />    
                    <Route path="/broad-street-market" element={<BroadStreetMarkeet />} />     
                    <Route path="/about" element={<AboutUs />} />  
                    <Route path="/giving-back" element={<GivingBack />} />    
                    <Route path="/yourpower" element={<YourPower />} />  
                    <Route path="/yourpower2" element={<YourPower2 />} />  
                    <Route path="/yourpower3" element={<YourPower3 />} />  
                    <Route path="/yourpower3a" element={<YourPower3a />} />     
                    <Route path="/yourpower3b" element={<YourPower3b />} />     
                    <Route path="/yourpower3c" element={<YourPower3c />} />     
                    <Route path="/yourpower4" element={<YourPower4 />} />     
                    <Route path="*" element={<HomePage />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default AllRoutes;