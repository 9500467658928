import React from "react";
import Footer from "../../Reuseable Components/Footer";
import Header from "../../Reuseable Components/Header";
import HeaderInner from "../../Reuseable Components/HeaderInner";
import '../../Assests/Css/Contact.css';
import TopFooterCollage from "../../Reuseable Components/TopFooterCollage";
import { cont_img1, cont_img2, cont_img3 } from "../../MediaConstant";

const Contact = () => {
    return (
        <div className="common_bg_back Contact ">
            <Header />
            <HeaderInner props={"Contact Us"} />
            <div className="Contact_Page">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359294.213879213!2d-113.71754000532059!3d36.24708982631331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1658398554694!5m2!1sen!2s" className="map_contact my-5" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mb-5">
                            <h1 className="contact_form">Contact Form</h1>
                        </div>
                        <div className="col-lg-3 mb-5">
                            <div className="crd_contact mb-4">
                                <div className="d-flex">
                                    <img className="crd_img" src={cont_img1} alt="contact image one" />
                                    <p className="crd_headng">Phone</p>
                                </div>
                                <p className="toll_free mb-1">TOLL FREE : <span className="lght-text "> 0000-0000-0000</span></p>
                                <p className="toll_free mb-0">Fax : <span className="lght-text"> 1232-456-7489</span></p>
                            </div>
                            <div className="crd_contact mb-4">
                                <div className="d-flex">
                                    <img className="crd_img" src={cont_img2} alt="contact image one" />
                                    <p className="crd_headng">Email</p>
                                </div>
                                <p className="toll_free mb-1"><span className="lght-text "> mail@example.com</span></p>
                                <p className="toll_free mb-0"><span className="lght-text"> mail@example.com</span></p>
                            </div>
                            <div className="crd_contact mb-4">
                                <div className="d-flex">
                                    <img className="crd_img" src={cont_img3} alt="contact image one" />
                                    <p className="crd_headng">Phone</p>
                                </div>
                                <p className="toll_free mb-1">TOLL FREE : <span className="lght-text "> 0000-0000-0000</span></p>
                                <p className="toll_free mb-0">Fax : <span className="lght-text"> 1232-456-7489</span></p>
                            </div>
                        </div>
                        <div className="col-lg-9 mb-5">
                            <input placeholder="Name" className="inpt_fld" />
                            <input placeholder="Email" className="inpt_fld" />
                            <input placeholder="Your Number" className="inpt_fld" />
                            <textarea placeholder="Your Message" rows={6} className="inpt_fld"></textarea>
                            <button className="btn btn-light bt-brwn px-send">Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <TopFooterCollage />
            <Footer />
        </div>
    )
}

export default Contact;