import React from "react";
import Footer from "../../Reuseable Components/Footer";
import Header from "../../Reuseable Components/Header";
import HeaderInner from "../../Reuseable Components/HeaderInner";
import "../../Assests/Css/BroadStreetMarkeet.css";
import BroadSlider from "../../Reuseable Components/BroadSlider";
import TopFooterCollage from "../../Reuseable Components/TopFooterCollage";
import {
  brd1,
  brd2,
  brd3,
  brd4,
  brd5,
  brd6,
  brd7,
  brd8,
  brd9,
  brd10,
  brd11,
  brd12,
  brd13,
  brd14,
  brd15,
  brd16,
  brd17,
  brd18,
  brd19,
  brd20,
  brd21,
  brdn7,
  brdb4,
  brdfd,
  brddip,
  brdpm,
  brdaf,
  brdst,
  matchacake,
} from "../../MediaConstant";

const BroadStreetMarkeet = () => {
  let SliderData1 = [
    {
      img: brd1,
      head: "Baked Fava Beans",
      category: "M",
      para: "Feta cheese / cucumber / bell pepper / herbs / butterflap bread",
      Price: "$ 13.50"
    },
    {
      img: brd2,
      head: "Spicy Oat Porridge",
      category: "F",
      para: "Ginger / chia seeds / spice mix / banana / walnuts",
      Price: "$ 8.50"
    },
    {
      img: brd3,
      head: "Blueberry Quinoa Porridge",
      category: "K",
      para: "Date molasses / pumpkin seeds /  rose water / creme fresh",
      Price: "$ 10.00"
    },
    {
      img: brdb4,
      head: "Yogurt Spread",
      category: "K",
      para: "Pickled eggplant / walnuts / za’atar / butterflap bread ",
      Price: "$ 7.00"
    },
    // {
    //   img: brd1,
    //   head: "Baked Fava Beans",
    //   category: "M",
    //   para: "Feta cheese / cucumber / bell pepper / herbs / butterflap bread",
    //   Price: "$ 13.50"
    // },
    // {
    //   img: brd2,
    //   head: "Spicy Oat Porridge",
    //   category: "N",
    //   para: "Ginger / chia seeds / spice mix / banana / walnuts",
    //   Price: "$ 8.50"
    // },
    // {
    //   img: brd3,
    //   head: "Blueberry Quinoa Porridge",
    //   category: "K",
    //   para: "Date molasses / pumpkin seeds /  rose water / creme fresh",
    //   Price: "$ 10.00"
    // },
    // {
    //   img: brdb4,
    //   head: "Yogurt Spread",
    //   category: "K",
    //   para: "Pickled eggplant / walnuts / za’atar / butterflap bread ",
    //   Price: "$ 7.00"
    // },
  ]

  let SliderData2 = [
    {
      img: brd4,
      head: "Watermelon Salad",
      category: "M",
      para: "Mint / feta cheese / pumpkin pesto / peppermint / oilfusion",
      Price: "$ 9.00"
    },
    {
      img: brd5,
      head: "Fennel & Zucchini Carpaccio",
      category: "K",
      para: "With smoked salmon / dijon mustard dressing / infused thyme oil",
      Price: "$ 11.00"
    },
    {
      img: brd6,
      head: "Grilled Zucchini & Pumpkin Pesto ",
      category: "N",
      para: "Feta cheese / kalamata olive / mixed greens / herbs / lemon herb dressing",
      Price: "$ 9.00"
    },
    {
      img: brdn7,
      head: "Green Lentil & Walnut Salad Wrap ",
      category: "N",
      para: "Brown lentils / roasted pepper / garlic / parsley / spinach / spring onion / walnut",
      Price: "$ 9.00"
    },
    // {
    //   img: brd4,
    //   head: "Watermelon Salad",
    //   category: "M",
    //   para: "Mint / feta cheese / pumpkin pesto / peppermint / oilfusion",
    //   Price: "$ 9.00"
    // },
    // {
    //   img: brd5,
    //   head: "Fennel & Zucchini Carpaccio",
    //   category: "K",
    //   para: "With smoked salmon / dijon mustard dressing / infused thyme oil",
    //   Price: "$ 11.00"
    // },
    // {
    //   img: brd6,
    //   head: "Grilled Zucchini & Pumpkin Pesto ",
    //   category: "N",
    //   para: "Feta cheese / kalamata olive / mixed greens / herbs / lemon herb dressing",
    //   Price: "$ 9.00"
    // },
    // {
    //   img: brdn7,
    //   head: "Green Lentil & Walnut Salad Wrap ",
    //   category: "N",
    //   para: "Brown lentils / roasted pepper / garlic / parsley / spinach / spring onion / walnut",
    //   Price: "$ 9.00"
    // }
  ]

  let SliderData3 = [
    {
      img: brd7,
      head: "Sage & Wild Orange Tamarind",
      category: "M",
      para: "Excellent for stress reliever & focus",
      Price: "$ 4.00"
    },
    {
      img: brd8,
      head: "Hibiscus Rose ",
      category: "K",
      para: "A refreshing & hydrating floral herbal tea",
      Price: "$ 4.00"
    },
    {
      img: brd9,
      head: "Almond Lemonade",
      category: "N",
      para: "A perfect mix of lemon & almond refresher",
      Price: "$ 4.00"
    },
    {
      img: brdfd,
      head: "Basil Lemonade",
      category: "N",
      para: "The ancient flower that refreshes the palates",
      Price: "$ 4.00"
    },

    // {
    //   img: brd7,
    //   head: "Sage & Wild Orange Tamarind",
    //   category: "M",
    //   para: "Excellent for stress reliever & focus",
    //   Price: "$ 4.00"
    // },
    // {
    //   img: brd8,
    //   head: "Hibiscus Rose ",
    //   category: "K",
    //   para: "A refreshing & hydrating floral herbal tea",
    //   Price: "$ 4.00"
    // },
    // {
    //   img: brd9,
    //   head: "Almond Lemonade",
    //   category: "N",
    //   para: "A perfect mix of lemon & almond refresher",
    //   Price: "$ 4.00"
    // },
    // {
    //   img: brdfd,
    //   head: "Basil Lemonade",
    //   category: "N",
    //   para: "The ancient flower that refreshes the palates",
    //   Price: "$ 4.00"
    // }
  ]

  let SliderData4 = [
    {
      img: brd10,
      head: "Cauliflower & Caraway",
      category: "M",
      para: "Cauliflower / herb soft cheese / cumin caraway / oregano infused oil",
      Price: "$ 5.50"
    },
    {
      img: brd11,
      head: "Parsnip Dip",
      category: "F",
      para: "Parsnip / garlic / onion / route / nutmeg / sage infused oil",
      Price: "$ 7.00"
    },
    {
      img: brd12,
      head: "Roasted Pepper Walnut Dip",
      category: "K",
      para: "Feta goat cheese / roasted pepper / walnut / garlic /  herbs / thyme infused oil",
      Price: "$ 8.00"
    },
    {
      img: brddip,
      head: "Beet Hummus",
      category: "K",
      para: "Beets / garlic / tahini / roasted pumpkin seeds  / rosemary infused oil",
      Price: "$ 5.00"
    },
    // {
    //   img: brd10,
    //   head: "Cauliflower & Caraway",
    //   category: "M",
    //   para: "Cauliflower / herb soft cheese / cumin caraway / oregano infused oil",
    //   Price: "$ 5.50"
    // },
    // {
    //   img: brd11,
    //   head: "Parsnip Dip",
    //   category: "F",
    //   para: "Parsnip / garlic / onion / route / nutmeg / sage infused oil",
    //   Price: "$ 7.00"
    // },
    // {
    //   img: brd12,
    //   head: "Roasted Pepper Walnut Dip",
    //   category: "K",
    //   para: "Feta goat cheese / roasted pepper / walnut / garlic /  herbs / thyme infused oil",
    //   Price: "$ 8.00"
    // },
    // {
    //   img: brddip,
    //   head: "Beet Hummus",
    //   category: "K",
    //   para: "Beets / garlic / tahini / roasted pumpkin seeds  / rosemary infused oil",
    //   Price: "$ 5.00"
    // }
  ]

  let SliderData5 = [
    {
      img: brd13,
      head: "Thanksgiving Roll ",
      category: "M",
      para: "Cream cheese stuffing / cranberry pomegranate sauce",
      Price: "$ 13.00"
    },
    {
      img: brd14,
      head: "Veal Roll",
      category: "K",
      para: "Mushrooms stuffing / roasted pepper with cream sauce",
      Price: "$ 14.00"
    },
    {
      img: brd15,
      head: "Gluten Free Lasagna",
      category: "K",
      para: "Authentic 20 years in the making lasagna with three sauces bolognese / marinara / bechamel",
      Price: "$ 16.00"
    },
    {
      img: brdpm,
      head: "Mac & Cheese",
      category: "K",
      para: "Parmesan cheese / cream cheese / cauliflower sauce",
      Price: "$ 11.00",
    },
    // {
    //   img: brd13,
    //   head: "Thanksgiving Roll ",
    //   category: "M",
    //   para: "Cream cheese stuffing / cranberry pomegranate sauce",
    //   Price: "$ 13.00"
    // },
    // {
    //   img: brd14,
    //   head: "Veal Roll",
    //   category: "K",
    //   para: "Mushrooms stuffing / roasted pepper with cream sauce",
    //   Price: "$ 14.00"
    // },
    // {
    //   img: brd15,
    //   head: "Gluten Free Lasagna",
    //   category: "K",
    //   para: "Authentic 20 years in the making lasagna with three sauces bolognese / marinara / bechamel",
    //   Price: "$ 16.00"
    // },
    // {
    //   img: brdpm,
    //   head: "Mac & Cheese",
    //   category: "K",
    //   para: "Parmesan cheese / cream cheese / cauliflower sauce",
    //   Price: "$ 11.00",
    // },
  ]

  let SliderData6 = [
    {
      img: brd16,
      head: "Turkish Coffee “Frothy & Creamy”",
      category: "M",
      para: "Served with water / Turkish delight",
      Price: "$ 5.00"
    },
    {
      img: brd17,
      head: "Butter Coffee “Rich & Creamy”",
      category: "K",
      para: "Hormone free butter / fresh espresso blended",
      Price: "$ 4.00"
    },
    {
      img: brd18,
      head: "Green Coffee “Pure Awakening”",
      category: "N",
      para: "Unroasted coffee / cardamom / served with a stuffed date",
      Price: "$ 6.00"
    },
    {
      img: brdaf,
      head: "Adani Chai “Rich & Perfect”",
      category: "N",
      para: "Rich and spicy with cinnamon / clove / cardamom milk served with butter cookie",
      Price: "$ 6.00"
    },
    // {
    //   img: brd16,
    //   head: "Turkish Coffee “Frothy & Creamy”",
    //   category: "M",
    //   para: "Served with water / Turkish delight",
    //   Price: "$ 5.00"
    // },
    // {
    //   img: brd17,
    //   head: "Butter Coffee “Rich & Creamy”",
    //   category: "K",
    //   para: "Hormone free butter / fresh espresso blended",
    //   Price: "$ 14.00"
    // },
    // {
    //   img: brd18,
    //   head: "Green Coffee “Pure Awakening”",
    //   category: "N",
    //   para: "Unroasted coffee / cardamom / served with a stuffed date",
    //   Price: "$ 16.00"
    // },
    // {
    //   img: brdaf,
    //   head: "Adani Chai “Rich & Perfect”",
    //   category: "N",
    //   para: "Rich and spicy with cinnamon / clove / cardamom milk served with butter cookie",
    //   Price: "$ 6.00"
    // }
  ]

  let SliderData7 = [
    {
      img: matchacake,
      head: "Matcha Cake",
      category: "M",
      para: "Almond flour / matcha / cream cheese / diet molasses",
      Price: "$ 4.50"
    },
    {
      img: brd20,
      head: "Date Parfait",
      category: "K",
      para: "Greek yogurt / diet molasses / granola",
      Price: "$ 4.50"
    },
    {
      img: brd21,
      head: "Blueberry Parfait",
      category: "N",
      para: "Rice pudding / pumpkin seed / blueberry chocolate sauce ",
      Price: "$ 6.00"
    },
    {
      img: brdst,
      head: "Semolina Cake ",
      category: "N",
      para: "Pumpkin seed / rose syrup / creme fresh ",
      Price: "$ 5.00"
    },
    // {
    //   img: brd19,
    //   head: "Matcha Cake",
    //   category: "M",
    //   para: "Almond flour / matcha / cream cheese / diet molasses",
    //   Price: "$ 4.50"
    // },
    // {
    //   img: brd20,
    //   head: "Date Parfait",
    //   category: "K",
    //   para: "Greek yogurt / diet molasses / granola",
    //   Price: "$ 4.50"
    // },
    // {
    //   img: brd21,
    //   head: "Blueberry Parfait",
    //   category: "N",
    //   para: "Rice pudding / pumpkin seed / blueberry chocolate sauce ",
    //   Price: "$ 6.00"
    // },
    // {
    //   img: brdst,
    //   head: "Semolina Cake ",
    //   category: "N",
    //   para: "Pumpkin seed / rose syrup / creme fresh ",
    //   Price: "$ 5.00"
    // },
  ]

  return (
    <>
      <div className="BroadStreetMarkeet common_bg_back">
        <Header />
        <HeaderInner props={"Broad Street Market"} />
        <div className="broadstreet_content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-5 text-center px-5 px-rez-broadstreet">
                <h1 className="main_heading mt-5">What To Know</h1>
                <p className="txt mt-4">
                  When ordering at Manderolas you will notice we have four genre
                  menus that are suggested to be beneficial towards individual
                  blood types.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="color-main-cont">
          <div className="container">
            <h2 className="main-heading">MANDEROLAS</h2>
            <h3 className="sub-heading">
              HEALTHY ITALIAN MEDITERRANEAN FUSION
            </h3>
            <p className="main-para">
              A dream inspired by a man who took the path untaken, asked the
              question’s one dared not to ask & ultimately built a legacy in the
              pursuit of happiness and sustainable contentment in one's life.
              Manderolas were born out of the idea that not only does food
              matter, nothing precedes the importance of food in one's life.
            </p>

            <div className="slide-bg-card">
              <h2 className="type f-head-broad-zam" id="breakfast">Breakfast</h2>
              <div>
                <div className="row">
                  {SliderData1?.map((item, index) => {
                    return (
                      <div className="col-md-3">

                        <div className="slider-card" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="w-100" />
                          </div>
                          <div className="content">
                            <p className="title">{item?.head}</p>
                            <p className="tags">*{item?.category}</p>
                          </div>
                          <p className="details">
                            {item?.para}
                          </p>
                          <p className="price">{item?.Price}</p>
                          {/* <p className="price">$ 13.50</p> */}
                          <button className="btn Mealbtn">Select Meals</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <BroadSlider content={SliderData1} /> */}
              </div>
              <h2 className="type mt-5 mb-4 f-head-broad-zam" id="nourishing_green">Nourishing Greens</h2>
              <div>
              <div className="row">
                  {SliderData2?.map((item, index) => {
                    return (
                      <div className="col-md-3">

                        <div className="slider-card" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="w-100" />
                          </div>
                          <div className="content">
                            <p className="title">{item?.head}</p>
                            <p className="tags">*{item?.category}</p>
                          </div>
                          <p className="details">
                            {item?.para}
                          </p>
                          <p className="price">{item?.Price}</p>
                          {/* <p className="price">$ 13.50</p> */}
                          <button className="btn Mealbtn">Select Meals</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <BroadSlider content={SliderData2} /> */}
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <section className=" slide-bg-card noccrd">
            <div className=" mt-3">
              <h2 className="type mt-5 mb-4 f-head-broad-zam" id="fresh_artisan">Fresh Artisan Drinks</h2>
              <div>
              <div className="row">
                  {SliderData3?.map((item, index) => {
                    return (
                      <div className="col-md-3">

                        <div className="slider-card" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="w-100" />
                          </div>
                          <div className="content">
                            <p className="title">{item?.head}</p>
                            <p className="tags">*{item?.category}</p>
                          </div>
                          <p className="details">
                            {item?.para}
                          </p>
                          <p className="price">{item?.Price}</p>
                          {/* <p className="price">$ 13.50</p> */}
                          <button className="btn Mealbtn">Select Meals</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <BroadSlider content={SliderData3} /> */}
              </div>
            </div>
          </section>

        </div>
        <section className="color-main-cont">
          <div className="container">
            <div className="slide-bg-card mt-3">
              <h2 className="type mt-5 mb-4 f-head-broad-zam" id="dipssec">DIPS</h2>
              <div>
              <div className="row">
                  {SliderData4?.map((item, index) => {
                    return (
                      <div className="col-md-3">

                        <div className="slider-card" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="w-100" />
                          </div>
                          <div className="content">
                            <p className="title">{item?.head}</p>
                            <p className="tags">*{item?.category}</p>
                          </div>
                          <p className="details">
                            {item?.para}
                          </p>
                          <p className="price">{item?.Price}</p>
                          {/* <p className="price">$ 13.50</p> */}
                          <button className="btn Mealbtn">Select Meals</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <BroadSlider content={SliderData4} /> */}
              </div>
            </div>
          </div>
        </section>
        <section className="color-main-cont">
          <div className="container">
            <div className="slide-bg-card mt-3">
              <h2 className="type mt-5 mb-4 f-head-broad-zam" id="power_meal">Power Meal</h2>
              <div>
              <div className="row">
                  {SliderData5?.map((item, index) => {
                    return (
                      <div className="col-md-3">

                        <div className="slider-card" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="w-100" />
                          </div>
                          <div className="content">
                            <p className="title">{item?.head}</p>
                            <p className="tags">*{item?.category}</p>
                          </div>
                          <p className="details">
                            {item?.para}
                          </p>
                          <p className="price">{item?.Price}</p>
                          {/* <p className="price">$ 13.50</p> */}
                          <button className="btn Mealbtn">Select Meals</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <BroadSlider content={SliderData5} /> */}
              </div>
              <h2 className="type mt-5 mb-4 f-head-broad-zam" id="artisan_coffee">Artisan Coffee & More</h2>
              <div>
                <div className="row">
                  {SliderData6?.map((item, index) => {
                    return (
                      <div className="col-md-3">

                        <div className="slider-card" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="w-100" />
                          </div>
                          <div className="content">
                            <p className="title">{item?.head}</p>
                            <p className="tags">*{item?.category}</p>
                          </div>
                          <p className="details">
                            {item?.para}
                          </p>
                          <p className="price">{item?.Price}</p>
                          {/* <p className="price">$ 13.50</p> */}
                          <button className="btn Mealbtn">Select Meals</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <BroadSlider content={SliderData6} /> */}
              </div>
            </div>
          </div>
        </section>
        <section className="color-main-cont">
          <div className="container">
            <div className="slide-bg-card mt-3">
              <h2 className="type mt-5 mb-4 f-head-broad-zam" id="sweets_sec">Sweets</h2>
              <div>
              <div className="row">
                  {SliderData7?.map((item, index) => {
                    return (
                      <div className="col-md-3">

                        <div className="slider-card" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="w-100" />
                          </div>
                          <div className="content">
                            <p className="title">{item?.head}</p>
                            <p className="tags">*{item?.category}</p>
                          </div>
                          <p className="details">
                            {item?.para}
                          </p>
                          <p className="price">{item?.Price}</p>
                          {/* <p className="price">$ 13.50</p> */}
                          <button className="btn Mealbtn">Select Meals</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* <BroadSlider content={SliderData7} /> */}
              </div>
            </div>
          </div>
        </section>

        <section className="color-main-cont">
          <div className="container">
            <div className="slide-bg-card mt-3">
              <div className="graphic-bg">
                <h2 className="type">*M</h2>
                <p className="main-title">Mediterranean Menu</p>
                <p className="para">
                  We are known as the “Cultivators”. Beans & grains Key! Our
                  Mediterranean menu incorporates ancient manmade spices & herbs
                  into our modern-day cuisines. No red meat but you can find
                  yourself enjoying poultry and proteins of the sea! Always on
                  the go, but want a balanced diet? This is your vegetarian
                  option.{" "}
                </p>
                <p className="sub-para">
                  Recommended for blood type<span>A</span>
                </p>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-4 mb-4">
                  <div className="keto-right">
                    <div className="header">
                      <p className="title">Keto Menu</p>
                      <p className="tag">*K</p>
                    </div>
                    <p className="para">
                      We are known as the “Hunter” Our Keto menu gives you jam
                      packed high protein options while avoiding the three C’s.{" "}
                    </p>
                    <div className="multi-tags">
                      <p>- Corn</p>
                      <p>- Cabbage</p>
                      <p>- Protein</p>
                      <p>- Cauliflower</p>
                    </div>

                    <div className="header2">
                      <p className="bld-type">Recommended for blood type</p>
                      <p className="bld">O</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6  mt-4 mb-4">
                  <div className="keto-left">
                    <div className="header">
                      <p className="title">Nomad Menu</p>
                      <p className="tag">*N</p>
                    </div>
                    <p className="para">
                      Known as the “Travelers Diet” Our Nomad menu focuses on a
                      wide variety of cross global cuisine with a well-balanced
                      fusion of flavors. Don’t worry our Nomad menu will have you
                      balanced daily. No Wheat, No Problem! Red meat is key!
                    </p>

                    <div className="header2">
                      <p className="bld-type">Recommended for blood type</p>
                      <p className="bld">B</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="graphic-bg">
                <h2 className="type">*F</h2>
                <p className="main-title">Fusion Menu</p>
                <p className="para">
                  We are known as the “Enigmas”. Do you like fish or turkey?
                  M... Maybe you find yourself liking a really good sauce or
                  stew! Our Fusion menu option allows you to enjoy a balanced
                  diet. You can enjoy a little more, such as meat, veggies &
                  even dairy.{" "}
                </p>
                <p className="sub-para">
                  Recommended for blood type<span>AB</span>
                </p>
              </div>
            </div>

            <p className="bottom-head mt-5">
              Practice Mindful Eating Ask About Our Daily Specials{" "}
            </p>
            <p className="bottom-para mb-3">
              Please be advised that our food may contain nut products, please
              ask a staff member about ingredients used in your meal before
              ordering. Thank you.
            </p>
          </div>
        </section>
      </div>
      <TopFooterCollage />
      <Footer />
    </>
  );
};

export default BroadStreetMarkeet;
