import React from "react";
import '../Assests/Css/Recepie.css';
import SearchIcon from '@mui/icons-material/Search';
import { pop_reci1, pop_reci2, recentrec1, recentrec2 } from "../MediaConstant";
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const RecepieFilters = () => {
    return (
        <div className="RecepieFilters">
            <div className="d-flex justify-center">
                <input type='text' placeholder="SEARCH..." className="header_search_fld" />
                <div className="brwn_bx"><SearchIcon /></div>
            </div>
            <div className="flx-advncsrch mr-2 mt-2">
                <div className="brwn_line"></div>
                <p className="advnc_srch">ADVANCED SEARCH</p>
            </div>
            <p className="filter_head">Recipe Types</p>
            <div className="mb-4">
                <ul className="ul_pad_zero">
                    <li className="ul_tick_round_white">
                        <div className="d-flex justify-spacebt">
                            <span className="txt-cap">Italian Recipes</span>
                        </div>
                    </li>
                    <li className="ul_tick_round_white">
                        <div className="d-flex justify-spacebt">
                            <span className="txt-cap">Asian Recipes</span>
                        </div>
                    </li>
                    <li className="ul_tick_round_white">
                        <div className="d-flex justify-spacebt">
                            <span className="txt-cap">American Recipes</span>
                        </div>
                    </li>
                    <li className="ul_tick_round_white">
                        <div className="d-flex justify-spacebt">
                            <span className="txt-cap">Italian Recipes</span>
                        </div>
                    </li>
                    <li className="ul_tick_round_white">
                        <div className="d-flex justify-spacebt">
                            <span className="txt-cap">Asian Recipes</span>
                        </div>
                    </li>
                    <li className="ul_tick_round_white">
                        <div className="d-flex justify-spacebt">
                            <span className="txt-cap">American Recipes</span>
                        </div>
                    </li>
                    <li className="ul_tick_round_white">
                        <div className="d-flex justify-spacebt">
                            <span className="txt-cap">American Recipes</span>
                        </div>
                    </li>
                </ul>
            </div>
            <p className="filter_head">Recent Recipe</p>
            <div className="recent_recepie_div">
                <div className="d-flex">
                    <img className="img_recentrecepie" src={recentrec1} alt="recent recipie" />
                    <div className="ml-2">
                        <p className="recepie_name">Mine</p>
                        <p className="mb-2 recepie_desc">Lorem Ipsum</p>
                    </div>

                </div>
                <div>
                    <p className="mb-2 mt-2 recepie_by">Recipe by <span>|</span>  john doe</p>
                </div>
            </div>
            <div className="recent_recepie_div">
                <div className="d-flex">
                    <img className="img_recentrecepie" src={recentrec2} alt="recent recipie" />
                    <div className="ml-2">
                        <p className="recepie_name">Mine</p>
                        <p className="mb-2 recepie_desc">Lorem Ipsum</p>
                    </div>

                </div>
                <div>
                    <p className="mb-2 mt-2 recepie_by">Recipe by <span>|</span>  john doe</p>
                </div>
            </div>
            <p className="filter_head mt-5">Popular Recipe</p>
            <div className="recent_recepie_div pb-3">
                <div className="d-flex">
                    <img className="img_popreci" src={pop_reci1} alt="recent recipie" />
                    <div className=" ml-4 mt-2">
                        <p className="recepie_name">RECEPIE NAME</p>
                        <p className="mb-2 recepie_desc "><StarIcon className="star_style" /><StarIcon className="star_style" /><StarIcon className="star_style" /><StarIcon className="star_style" /><StarHalfIcon className="star_style" /></p>
                    </div>
                </div>
            </div>
            <div className="recent_recepie_div brdr-btm-no pb-3">
                <div className="d-flex">
                    <img className="img_popreci" src={pop_reci2} alt="recent recipie" />
                    <div className=" ml-4 mt-2">
                        <p className="recepie_name">RECEPIE NAME</p>
                        <p className="mb-2 recepie_desc "><StarIcon className="star_style" /><StarIcon className="star_style" /><StarIcon className="star_style" /><StarIcon className="star_style" /><StarHalfIcon className="star_style" /></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecepieFilters;