import React from 'react';
import { foodCart3, home2, homex2, latest1, latest2, latest3, latest4, manu1, manu2, manu3, manu4, recipe5,one_on_one,givingfood,giveback,keto,fusion,meditarr,nomad,mediterraneannew,
    nomadnew,
    ketonew,
    fusionnew, } from '../../MediaConstant';

const LatestVideo = () => {
    return (
        <div className='LatestVideo'>
            <div className='container mb-5'>
                <div className='row mt-5 pt-3'>
                    <div className='col-lg-12 text-center'>
                        <p className='Latest_Videos_heading'>
                            {/* Latest Videos */}
                            BOOK YOUR COOKING SESSION
                            </p>
                    </div>
                    <div className='col-lg-9'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <img className='w-100 my-2' src={latest1} />
                            </div>
                            <div className='col-lg-4'>
                                <img className='w-100 my-2' src={latest2} />

                            </div>
                            <div className='col-lg-4'>
                                <img className='w-100 my-2' src={latest3} />

                            </div>
                            <div className='col-lg-4'>
                                <img className='w-100 my-2' src={latest4} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 text-center' >
                        <img className='w-100 mt-2 img-col-3s' src={one_on_one} />
                        <div className='brwn_crd-info px-4 py-4'>
                            <p className='comp_guidee'>Complete Guide of a <br/>Wellness Chef</p>
                            <p className='over100'>
                            Over 100 hand-picked, outrageously delicious recipes.
                            </p>
                            <button className='btn btn-light bt-blk mb-4'>Explore</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='nutirtnWrkshp ptmt-nutrin'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-2'></div>
                        <div className='col-lg-4'>
                            <div className='nutrnt_content'>
                                <p className='nut_heading'>
                                    {/* Giving Back */}
                                    Giving Food And Educating The Community
                                    </p>
                                <p className='nut_subheading'>THE ART OF HEALTHY EATING</p>
                                <p className='nut_txt'>Delicious, colourful, and satisfying meals: this is what plants can turn into if you get to know them.</p>
                                <button className='btn btn-light bt-navy d-inline-block'>Discover</button>
                            </div>
                        </div>
                        <div className='col-lg-6 px-0'>
                            <div className='img-wrapper'>
                            <figure>
                            <img className='w-100' src={giveback} alt="recepie" />
                            </figure>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className='whatsmenu'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 text-center'>
                            <p className='menuhead'>
                                {/* Whats your Power (Blood Type) */}
                                What’s Your Power Program
                                </p>
                            <p className='del'> Delivery Services</p>
                        </div>
                        <div className='col-lg-3 my-5 rez-mrgn-ltst'>
                            <div className='crd_menu'>
                                <img className='w-100' src={mediterraneannew} alt="recepie menu" />
                                <p className='menu_crd_hd'>Mediterranean</p>
                                <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                <p className='menu_dr-p'>2 Beverages</p>
                                <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                <div className='d-flex res-bt-dv'>
                                    <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                    <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 my-5 rez-mrgn-ltst'>
                            <div className='crd_menu'>
                                <img className='w-100' src={ketonew} alt="recepie menu" />
                                <p className='menu_crd_hd'>Keto</p>
                                <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                <p className='menu_dr-p'>2 Beverages</p>
                                <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                <div className='d-flex res-bt-dv'>
                                    <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                    <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-lg-3 my-5 rez-mrgn-ltst'>
                            <div className='crd_menu'>
                                <img className='w-100' src={nomadnew} alt="recepie menu" />
                                <p className='menu_crd_hd'>Nomad</p>
                                <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                <p className='menu_dr-p'>2 Beverages</p>
                                <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                <div className='d-flex res-bt-dv'>
                                    <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                    <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 my-5 rez-mrgn-ltst'>
                            <div className='crd_menu'>
                                <img className='w-100' src={fusionnew} alt="recepie menu" />
                                <p className='menu_crd_hd'>Fusion</p>
                                <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                <p className='menu_dr-p'>2 Beverages</p>
                                <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                <div className='d-flex res-bt-dv'>
                                    <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                    <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LatestVideo;