import React from 'react';
import { useNavigate } from 'react-router-dom';
import { manu1, manu2, manu3, manu4, powm1, powm2, powm3, powm4, powm5, powm6, powm7, ta1, ta2, ta3, ta4, ta5, ta6, ta7 } from '../../MediaConstant';
import Footer from '../../Reuseable Components/Footer';
import Header from '../../Reuseable Components/Header';
import HeaderInner from '../../Reuseable Components/HeaderInner';
import TopFooterCollage from '../../Reuseable Components/TopFooterCollage';
import Slider from 'react-slick/lib/slider';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const YourPower3a = () => {
    let navigate = useNavigate();

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className='power3-next'
                // style={{ ...style, display: "block", background: "red", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className="power3-prev"
                // style={{ ...style, display: "block", background: "green", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: dots => (
            <div
                style={{
                    // backgroundColor: "black",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px"
                }}
            >
                <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
            </div>
        ),
    };

    return (
        <div className="common_bg_back Your_Power ">
            <Header />
            <HeaderInner props={"Your Power"} />
            <div className='Your_Power_content'>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline">
                                <div class="lambi_line"></div>
                                <div class="flx_stepsboxes">
                                    <div className="box-wdth">
                                        <p class="bx-noactive">01</p>
                                        <p class="step1">Assesment</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">02</p>
                                        <p class="step1">Choose Your Power</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-active">03</p>
                                        <p class="step1">Select Your Plan</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">04</p>
                                        <p class="step1">Checkout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline pos-rel pt-3">
                                <Slider {...settings} className="slider_top_home">
                                    <div className='actv_brwn'>
                                        <p className='mb-0'>
                                            Fri
                                        </p>
                                        <p>
                                            Dec 10
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Sat
                                        </p>
                                        <p>
                                            Dec 11
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Sun
                                        </p>
                                        <p>
                                            Dec 12
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Mon
                                        </p>
                                        <p>
                                            Dec 13
                                        </p>
                                    </div>
                                    <div className='noactv_brwn'>
                                        <p className='mb-0'>
                                            Tues
                                        </p>
                                        <p>
                                            Dec 14
                                        </p>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gry_sec_power'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p className='menu_for_txt mb-0'>Menu for Fri, 10 Dec</p>
                                <p className='menu_for_box'>Add to Box</p>
                            </div>
                            <div className='col-lg-6 text-right'>
                                <button className='btn btn-light bt-brwn px-5'>SAVE</button>
                            </div>
                            <div className='col-lg-12 mb-0'>
                                <div className='line_gry_light mb-3'>

                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={ta1} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Roasted Pepper Walnut
                                    Dip</p>
                                <p className='top_cont_crd'>Feta goat cheese / roasted pepper / walnut / garlic /  herbs / thyme infused oil</p>
                                <p className='top_heading_crd'>$8.00</p>
                                <div>
                                    <button className='btn btn-light bt-light-brdrrd1 px-5 w-btn-pow show_other_pow1'>Add to Box</button>
                                    {/* <div className='del_btn_pow'>
                                        <span className='del_ico'>
                                            <DeleteSweepIcon />
                                        </span>
                                        <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4 '>
                                <img src={ta2} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Fennel & Zucchini
                                    Carpaccio</p>
                                <p className='top_cont_crd'>With smoked salmon / dijon mustard dressing / infused thyme oi</p>
                                <p className='top_heading_crd'>$ 11.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={ta3} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Veal
                                    Roll </p>
                                <p className='top_cont_crd'>Mushrooms stuffing / roasted pepper with cream sauce</p>
                                <p className='top_heading_crd'>$ 14.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={ta4} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Hibiscus
                                    Rose</p>
                                <p className='top_cont_crd'>A refreshing & hydrating floral herbal tea</p>
                                <p className='top_heading_crd'>$ 4.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={ta5} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Butter Coffee “Rich &
                                    Creamy</p>
                                <p className='top_cont_crd'>Hormone free butter / fresh espresso blended</p>
                                <p className='top_heading_crd'>$ 4.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={ta6} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Date
                                    Parfait</p>
                                <p className='top_cont_crd'>Greek yogurt / diet molasses / granola</p>
                                <p className='top_heading_crd'>$ 4.50</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>

                            </div>
                            <div className='col-lg-4 px-4 text-center my-4'>
                                <img src={ta7} className="img_powm" />
                                <p className='top_heading_crd mt-4'>Blueberry Quinoa
                                    Porridge</p>
                                <p className='top_cont_crd'>Date molasses / pumpkin seeds /  rose water / creme fresh</p>
                                <p className='top_heading_crd'>$ 10.00</p>
                                <div className='del_btn_pow'>
                                    <span className='del_ico'>
                                        <DeleteSweepIcon />
                                    </span>
                                    <button className='btn btn-light bt-brwn py-cus-poww'>Add to box</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TopFooterCollage />
                <Footer />
            </div>
        </div>
    )
};

export default YourPower3a;