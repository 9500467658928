import React from "react";
import { footerLogo, footer_logo } from "../MediaConstant";
import '../Assests/Css/Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            <div className="container pt-5">
                <div className="row pt-5">
                    <div className="col-lg-3">
                        <img src={footerLogo}  className="footer-logo"/>
                    </div>
                    <div className="col-lg-3 pr-5">
                        <p className="cat_head_txt">About</p>
                        <p className="cat_sm_txt pr-5">
                            Thank you for stopping by! On this blog we love to share wholesome and delicious recipes, perfect for home cooked family meals.
                        </p>
                    </div>
                    <div className="col-lg-3">
                        <p className="cat_head_txt">Categories</p>
                        <p className="cat_big_txt">BREAK FAST</p>
                        <p className="cat_big_txt">LUNCH</p>
                        <p className="cat_big_txt">DINNER</p>
                        <p className="cat_big_txt">DESSERTS</p>
                    </div>
                    <div className="col-lg-3">
                        <p className="cat_head_txt">Follow Us</p>
                        <p className="cat_big_txt">INSTAGRAM</p>
                        <p className="cat_big_txt">YOUTUBE</p>
                        <p className="cat_big_txt">FACEBOOK</p>
                        <p className="cat_big_txt">TWITTER</p>
                    </div>
                    <div className="col-lg-12 text-center mt-copy mb-4">
                        <p className="copyRight">Copyright © 2021 MANDEROLAS LLC. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;