import React from "react";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import '../../Assests/Css/Profile.css';

const ProfileTabs = () => {
    return (
        <div className="ProfileTabs_content">
            <div className="d-flex brdr-right-brwn my-3 py-1 pl-2" >
                <PersonOutlineOutlinedIcon className="mr-1"/>
                <p className="prof_txt">Account Info</p>
            </div>
            <div className="d-flex my-3 py-1 pl-2" >
                <NotificationsOutlinedIcon className="mr-1"/>
                <p className="prof_txt">Account Info</p>
            </div>
        </div>
    )
}

export default ProfileTabs;