import React from 'react';

const HeaderInner = (props) => {
    console.log(props);
    return (
        <div className='HeaderInner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 text-center'>
                        <h1 className='HeaderInner_h1'>{props?.props}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderInner;