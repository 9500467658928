import React from 'react'
import Footer from '../../Reuseable Components/Footer'
import Header from '../../Reuseable Components/Header'
import HeaderInner from '../../Reuseable Components/HeaderInner'
import TopFooterCollage from '../../Reuseable Components/TopFooterCollage'
import '../../Assests/Css/GivingBack.css'
import { badge, card, gift, smHeart } from '../../MediaConstant'

function GivingBack() {
  return (
   <>
       <div className="common_bg_back GivingBack ">
            <Header />
            <HeaderInner props={"Giving Back"} />
            <div className="container">
               <div className='inner-cont'>
                  <h2 className='main-title'>Give the gift of home-cooked meals</h2>
                  <p className='main-para'>A core component of the Manderolas business is giving back to the community that serves us. By contributing to the overall improvement of the community by empowering the less fortunateamong us, through health and nutrition awareness programs, cooking workshops and other initiatives geared towards an enriching, affordable and sustainable lifestyle transformation. In addition to providing a plat form for local artists, chefs, and other members within the cultural segment of our community to showcase their expertise and to inspire generations through their arts and craft</p>
                  <div className='btn-container'>
                      <button className='btn bt-brwn mr-3 m-rez-giving-back' >Buy Gift Card</button>
                      <button className='btn whiteBtn' >Redeem Gift Card</button>
                  </div>

                  <div>
                    <p className='gift-title'>Buy a gift card</p>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <p className='sm-title'>How much would you like to gift?</p>
                          <p className='para'>Recipients can use your gift towards any meal plan and recipes of their choice.</p>

                          <div className='gift-container mt-4'>
                            <div className='price-tags'>
                              <p>$70.00</p>
                            </div>
                            <div className='price-tags ml-3'>
                              <p>$70.00</p>
                            </div>
                          </div>
                          <div className='gift-container mt-4'>
                            <div className='price-tags'>
                              <p>$70.00</p>
                            </div>
                            <div className='price-tags ml-3'>
                              <p>$70.00</p>
                            </div>
                          </div>

                          <ul className='mt-5 mb-5'>
                            <li>$70 feeds 2 people with 3 meals a week</li>
                            <li>We'll email your gift to the recipient, as well as a printable version of the gift card to your inbox</li>
                            <li>Gift Cards are subject to our Gift Card Terms.</li>
                          </ul>

                        </div>
                        <div className='col-lg-6'>
                          <div className='cart-img'>
                            <img src={card} alt="" className='image-fluid' />
                          </div>
                        </div>

                        <div className='col-lg-6'>
                          <p className='sm-title mb-3'>Who should we send it to?</p>
                          <div>
                              <input type="text" placeholder='Name'/>
                          </div>
                          <div>
                              <input type="text" placeholder='Email'/>
                          </div>
                          <div>
                              <textarea type="text" placeholder='Add Personal Message'></textarea>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <p className='sm-title mb-3'>When would you like us to send the email?</p>
                          <div>
                              <input type="date" placeholder='Select Selecting Date'/>
                          </div>
                          <div>
                            <button className='btn bt-brwn btn-Width'>Go to Checkout</button>
                          </div>
                        </div>
                        <hr />
                        <div className='col-lg-6'>
                        <p className='redeem_gift_h'>Redeem a gift card</p>
                        <p className='redeem_gift_p'>Received a gift card? Lucky you! Redeem it here and get ready for some delicious home cooking.</p>
                          <div>
                              <input type="text" placeholder='Enter Code'/>
                          </div>
                          <div>
                            <button className='btn bt-brwn btn-Width'>Go to Checkout</button>
                          </div>
                        </div>
                      </div>
                  </div>
               </div>
            </div>
            <div className='badges-container'>
              <div className='container'>
                <div className='row'>
                <div className='col-lg-4'>
                  <div className='icon-img'>
                    <img src={gift} alt="" className='image-fluid' />
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore.
                  </p>
                </div>
                <div className='col-lg-4'>
                <div className='icon-img'>
                    <img src={badge} alt="" className='image-fluid' />
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore.
                  </p>
                </div>
                <div className='col-lg-4'>
                <div className='icon-img'>
                    <img src={smHeart} alt="" className='image-fluid' />
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore.
                  </p>
                </div>
                </div>
              </div>
                
            </div>
            <TopFooterCollage />
            <Footer />
        </div>
   </>
  )
}

export default GivingBack