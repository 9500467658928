import React from "react";
import { about2, aboutus1, book, manu4 } from "../../MediaConstant";
import Footer from "../../Reuseable Components/Footer";
import Header from "../../Reuseable Components/Header";
import HeaderInner from "../../Reuseable Components/HeaderInner";
import '../../Assests/Css/About.css';
import TopFooterCollage from "../../Reuseable Components/TopFooterCollage";
import FeaturedPlans from "./FeaturedPlans";
import BroadSlider from "../../Reuseable Components/BroadSlider";

const AboutUs = () => {
    return (
        <div className="AboutUs common_bg_back">
            <Header />
            <HeaderInner props={"About Us"} />
            <div className='broadstreet_content'>
                <div className="container">
                    <div className="row mt-5 mt-rez-aboutus">
                        <div className="col-lg-12 mt-2 text-center mt-3 mb-3">
                            <p className="main-para">A dream inspired by a man who took the path untaken, asked the questions one dared not to ask and ultimately built a legacy in thepursuit of happiness and sustainable contentment in one’s life. Manderolas was born out of the idea that not only does food matter, but nothing also precedes the importance of food in one’slife.Featuring recipes accumulated and modified over time from around the world, with a particular emphasis on nutritional value without the compromise on taste. Incorporating ancient herbs and remedies into modern-day cuisine, inspired by various global diets and food remedies, including the Mediterranean, macrobiotic, ayurvedic, and good ol’ soul food, Manderolas aims to transform your life through the unrivaled power of food</p>
                          </div>
                    </div>
                </div>

                <img className="w-100 mt-4" src={aboutus1} alt="about us" />

                <div className="container">
                    <div className="row mt-cus-about">
                        {/* <div className="col-lg-12 px-5 mt-4 mb-5 text-center">
                            <p className="txt">A dream inspired by a man who took the path untaken, asked the questions one dared not to ask and ultimately built a legacy in thepursuit of happiness and sustainable contentment in one’s life. Manderolas was born out of the idea that not only does food matter, but nothing also precedes the importance of food in one’slife.Featuring recipes accumulated and modified over time from around the world, with a particular emphasis on nutritional value without the compromise on taste. Incorporating ancient herbs and remedies into modern-day cuisine, inspired by various global diets and food remedies, including the Mediterranean, macrobiotic, ayurvedic, and good ol’ soul food, Manderolas aims to transform your life through the unrivaled power of food</p>
                        </div> */}
                        <div className="col-lg-6 mb-2">
                          <div className="abt-cent-mid">
                          <h1 className="who_are_we mb-4">Who is Medina Manderola?</h1>
                            <p className="txt">
                                Medina has unique experiences and perspectives that allow her toincorporate the best practices the world has to offer into her philosophy and holistic approach to Wellness. Being an Italian African American born in The Middle East, she realized that there is a great reservoir of knowledge about health and wellness that resides in the wisdom of the Middle Eastern traditions and western education. Her mission is to link the ancient knowledge
                                with modern health sciences to help others achieve optimal health throughout their conscious decisions.Medina is a Certified Health Coach, Personal Trainer and Wellness Chef. She possesses a wide range of expertise and incorporates her entire background of holistic health and wellness into her consultative approach. Medina played a big part in the health andfitness initiative for women in the middle east, cities including Dubai and Saudi Arabia. She was a private wellness chef in Miami and Dubai. She takes her clients to a journey of the senses through the Mediterranean, and the Middle East accompanied by science and aromatherapy
                            </p>
                          </div>
                          
                        </div>
                        <div className="col-lg-6 mb-2 px-5 px-rezz-about">
                            <img className="w-100" src={about2} />
                        </div>
                        <div className="col-lg-12 mt-abtz-cus2 mb-5">
                        <p className="text-center who_are_we">Featured Power Plans</p>
                        {/* <BroadSlider /> */}
                            <FeaturedPlans />
                        </div>
                    </div>
                </div>

            </div>
            <div className="cooking-book">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pos-rel pr-5">
                            <div className="book-innerCont">
                            <img src={book} alt="" className="image-fluid w-100 about_book" />
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="right-content">
                                <p className="small-title">Enjoy our Recipes Book</p>
                                <h2 className="title">Our Cook Book is Coming Soon</h2>
                                <p className="small-para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting</p>
                                <div>
                                    <button className="btn view-btn">View recipies</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TopFooterCollage />
            <Footer />
        </div>
    )
}

export default AboutUs;