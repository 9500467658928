import React from "react";
import { useNavigate } from "react-router-dom";
import { dietbg1, dietbg2, home1, homeDietLst, home_2, recipe1, recipe2, recipe3, recipe4, recipe5, recipe6,breakfast,nourishinggreen,dipimg,artcoffee,dips,norishgreen,sweetss,artdrink,powermeal,heathcoach,breakfasts,womenworkoutnew,sweetsnew,freshartisandrinks } from "../../MediaConstant";

const DietCoach = () => {
    const navigate = useNavigate()
    return (
        <div className="DietCoach">
            <div className="flx-itemz-diet">
                <div style={{ backgroundImage: `url("${womenworkoutnew}")` }} className="flx-dietz-frst">

                </div>
                <div style={{ backgroundImage: `url("${dietbg2}")` }} className="flx-dietz-sec">
                    <div className="mx-wdth-dietcoch wow animate__animated animate__fadeInRight">
                        <p className="diet_mainHead">Medina Manderola your <br /> Health Coach</p>
                        <p className="ml-pln mb-3 text-white">Medina Manderola your Health Coach</p>
                        <p className="ml-txtt text-white">How about waking up with twice as much energy? Finally running that marathon you’ve been putting off? Managing your food intolerances better? No dream is too far when you ask plant-based foods nicely..</p>
                        <button className="btn btn-light bt-navy" onClick={()=>navigate("/signup")}>Get Started</button>
                    </div>
                </div>
            </div>
            <div className="what_would">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 my-5 text-center wow animate__animated animate__fadeInDown">
                            <p className="what_would_head wow animate__animated animate__fadeInDown">
                            BROAD STREET MARKET
                            {/* BRANCH STREET  */}
                                {/* What’s your Power Meal for today? */}
                                 {/* MARKET MENU */}
                                 
                                 </p>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-4 my-4 text-center">
                            <div style={{ backgroundImage: `url("${breakfasts}")` }} className="cookTodayCrd">
                                {/* <button className="btn btn-light lunchDinnBtmn">Seasonal Salads</button> */}
                                <button className="btn btn-light lunchDinnBtmn"
                                onClick={()=>navigate("/broad-street-market")}
                                ><a href="#breakfast">BREAKFAST</a> </button>
                            </div>
                        </div>
                        <div className="col-lg-4 my-4 text-center">
                            <div style={{ backgroundImage: `url("${dips}")` }} className="cookTodayCrd">
                                {/* <button className="btn btn-light lunchDinnBtmn">Seasonal Dips</button> */}
                                <button className="btn btn-light lunchDinnBtmn" onClick={()=>navigate("/broad-street-market")}>
                                    <a href="#dipssec">DIPS</a> </button>
                            </div>
                        </div>
                        <div className="col-lg-4 my-4 text-center">
                            <div style={{ backgroundImage: `url("${powermeal}")` }} className="cookTodayCrd">
                                {/* <button className="btn btn-light lunchDinnBtmn">Seasonal Soups</button> */}
                                <button className="btn btn-light lunchDinnBtmn" onClick={()=>navigate("/broad-street-market")}>
                                    <a href="#power_meal">POWER MEALS</a>  </button>
                            </div>
                        </div>
                        <div className="col-lg-4 my-4 text-center">
                            <div style={{ backgroundImage: `url("${norishgreen}")` }} className="cookTodayCrd">
                                {/* <button className="btn btn-light lunchDinnBtmn">Seasonal Lunch Special</button> */}
                                <button className="btn btn-light lunchDinnBtmn" onClick={()=>navigate("/broad-street-market")}> 
                                <a href="#nourishing_green">NOURISHING GREENS</a>  </button>
                            </div>
                        </div>
                        <div className="col-lg-4 my-4 text-center">
                            <div style={{ backgroundImage: `url("${sweetsnew}")` }} className="cookTodayCrd">
                                {/* <button className="btn btn-light lunchDinnBtmn">Seasonal Dinner Special</button> */}
                                <button className="btn btn-light lunchDinnBtmn" onClick={()=>navigate("/broad-street-market")}>
                                    <a href="#sweets_sec">SWEETS</a>  </button>
                            </div>
                        </div>
                        <div className="col-lg-4 my-4 text-center">
                            <div style={{ backgroundImage: `url("${artcoffee}")` }} className="cookTodayCrd">
                                {/* <button className="btn btn-light lunchDinnBtmn">Seasonal Extras</button> */}
                                <button className="btn btn-light lunchDinnBtmn" onClick={()=>navigate("/broad-street-market")}>
                                    <a href="#artisan_coffee">ARTISAN COFFEE</a>  </button>
                            </div>
                        </div>
                        <div className="col-lg-4 my-4 text-center">
                            <div style={{ backgroundImage: `url("${freshartisandrinks}")` }} className="cookTodayCrd">
                                {/* <button className="btn btn-light lunchDinnBtmn">Seasonal Extras</button> */}
                                <button className="btn btn-light lunchDinnBtmn" onClick={()=>navigate("/broad-street-market")}>
                                    <a href="#fresh_artisan">FRESH ARTISAN DRINKS </a>  </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="homeDietLstImg">
                <div className="row ">
                    <div className="col-lg-6 px-0">
                        <div className="home_img_brwnn pl-homeDietLstimg">
                            <div className=" wow animate__animated animate__fadeInLeft">
                                <p className="subs_two">FREE HEALTH ASSESSMENT AND ONE DAY MENU
                                 {/* <br /> Calendar & Newsletter */}
                                 </p>
                                <p className="subs_txt_two">Receive out new tasty recipes directly to your inbox. Don’t worry – your information is secret safe with us!</p>
                                <div className="d-flex mb-5 pb-5">
                                    <input type='text' className="subs_txt_two_fld" placeholder="Your mail" /><button className="btn btn-light bt-blk">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 px-0">
                        <img className="home2zzz" alt=" home content" src={home_2}></img>
                    </div>
                </div>
            </div>

            <div style={{ backgroundImage: `url("${homeDietLst}")` }} className="homeDietLstImg">
                <div className="container">
                    <div className="row">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DietCoach;