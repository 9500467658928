import React, { useState } from "react";
import { Recipie_img, stp1, stp2, stp3, stp4, videoImg } from "../../../MediaConstant";
import Footer from "../../../Reuseable Components/Footer";
import Header from "../../../Reuseable Components/Header";
import HeaderInner from "../../../Reuseable Components/HeaderInner";
import RecepieFilters from "../../../Reuseable Components/RecepieFilters";
import TopFooterCollage from "../../../Reuseable Components/TopFooterCollage";
import "../../../Assests/Css/RecipeDetails.css";
import { Rating } from 'react-simple-star-rating'

const RecipeDetails = () => {
  const stpData = [
    {
      id:1,
      img: stp1,
    },
    {
      id:1,
      img: stp2,
    },
    {
      id:1,
      img: stp3,
    },
    {
      id:1,
      img: stp4,
    },
  ]

  const [rating, setRating] = useState(0) 

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)
  }

  return (
    <div className="OurRecepies common_bg_back">
      <Header />
      <HeaderInner props={"Cooking Workshops "} />
      <div className="OurRecipes_content">
        <div className="container">
          <div className="row rcp-bothSide">
            <div className="col-lg-3 my-5">
              <RecepieFilters />
            </div>
            <div className="col-lg-9">
              <div className="recipe-deatils">
                <div className="name-time-cont">
                  <p className="title">Craft Burger</p>
                  <p>Total Time : 30 mins</p>
                </div>
                <div>
                  <img src={videoImg} alt="" className="image-fluid w-100" />
                </div>

                <div className="detail-card">
                  <div className="name-rating">
                    <p className="name">Chief Name</p>
                    <div className="main-rating-cont">
                      <p className="rating mr-2">Rating : 3.2 /<span>5.0</span></p>
                      <Rating onClick={handleRating} ratingValue={90} 
                        size={"18"}
                        readonly={true}
                      />
                    </div>
                  </div>
                  <p className="main-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum
                  </p>

                  <div className="inner-card">
                      <p className="main-title">Nutrients</p>
                      <hr/>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">01 Calories</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">05 Protien</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">02 Deitary Fiber</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">06 Fat Saturated</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">03 Fat Total</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">07 Sodium</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">04 Cholesterol</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="nut-bottom">
                            <p className="series">08 Energy</p>
                            <p className="num">500</p>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div className="row steps-cont">
                    {
                      stpData?.map((item,index)=>{
                        return(
                         <>
                             <div className="col-lg-4" key={index}>
                          <div>
                            <img src={item?.img} alt="" className="image-fluid w-100" />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <p className="title">Step No : 01</p>
                          <p className="para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting</p>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                          <hr  className="hr-step"/>
                        </div>
                        <div className="col-lg-1"></div>
                         </>
                        )
                      })
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopFooterCollage />
      <Footer />
    </div>
  );
};

export default RecipeDetails;
