import React from "react";
import Footer from "../../Reuseable Components/Footer";
import Header from "../../Reuseable Components/Header";
import HeaderInner from "../../Reuseable Components/HeaderInner";
import TopFooterCollage from "../../Reuseable Components/TopFooterCollage";
import '../../Assests/Css/YourPower.css';
import { useNavigate } from "react-router-dom";

const YourPower = () => {
    const navigate = useNavigate();

    return (
        <div className="common_bg_back Your_Power ">
            <Header />
            <HeaderInner props={"Your Power"} />
            <div className="Your_Power_content">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div class="maindiv_lambiline">
                                <div class="lambi_line"></div>
                                <div class="flx_stepsboxes">
                                    <div className="box-wdth">
                                        <p class="bx-active">01</p>
                                        <p class="step1">Assesment</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">02</p>
                                        <p class="step1">Choose Your Power</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">03</p>
                                        <p class="step1">Select Your Plan</p>
                                    </div>
                                    <div className="box-wdth">
                                        <p class="bx-noactive">04</p>
                                        <p class="step1">Checkout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="your_p_Asses">1 .Assesment</h1>
                        </div>
                        <div className="col-lg-6 my-2">
                            <input type="text" placeholder="First Name" className="inpt-fld" />
                        </div>
                        <div className="col-lg-6 my-2">
                            <input type="text" placeholder="Last Name" className="inpt-fld" />
                        </div>
                        <div className="col-lg-6 my-2">
                            <input type="text" placeholder="Email" className="inpt-fld" />
                        </div>
                        <div className="col-lg-6 my-2">
                            <input type="text" placeholder="Phone" className="inpt-fld" />
                        </div>
                        <div className="col-lg-3 my-2">
                            <input type="text" placeholder="Age" className="inpt-fld" />
                        </div>
                        <div className="col-lg-3 my-2">
                            <input type="text" placeholder="Birth" className="inpt-fld" />
                        </div>
                        <div className="col-lg-3 my-2">
                            <input type="text" placeholder="Height" className="inpt-fld" />
                        </div>
                        <div className="col-lg-3 my-2">
                            <input type="text" placeholder="Blood Type" className="inpt-fld" />
                        </div>
                        <div className="col-lg-9 my-2">
                            <input type="text" placeholder="Favourite Food" className="inpt-fld" />
                        </div>
                        <div className="col-lg-3 my-2">
                            <input type="text" placeholder="Weight goal" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                            <input type="text" placeholder="Place of Birth" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                           <p className="brw_clr mt-4 mb-2">Please explain below</p>
                        </div>
                        <div className="col-lg-12 my-2">
                            <textarea rows={4} placeholder="Do you have any health conditions or concerns?" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                            <textarea rows={4} placeholder="Do you take any medications or supplements?" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                            <textarea rows={4} placeholder="What did you eat often as a child?" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                            <textarea rows={4} placeholder="What are your eating habits now?" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                            <textarea rows={4} placeholder="Do you cook?" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                            <textarea rows={4} placeholder="What percentage of your food is home cooked?" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-2">
                            <textarea rows={4} placeholder="Do you have an addictions to sugar, caffeine, cigarettes, alcohol?...etc" className="inpt-fld" />
                        </div>
                        <div className="col-lg-12 my-4">
                            <button onClick={()=>{navigate('/yourpower2')}} className="btn btn-light bt-brwn mb-5">Proceed</button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <TopFooterCollage />
            <Footer />
        </div>
    )
}

export default YourPower;