import React from "react";
import Slider from 'react-slick/lib/slider';
import '../../Assests/Css/About.css';
import { manu1, manu2, manu3, manu4, slider1 } from "../../MediaConstant";
const FeaturedPlans = () => {

    function SampleNextArrow1(props) {
        const { style, onClick } = props;
        return (
            <div
                className='Slider_top_custom_arrows-about-next'
                // style={{ ...style, display: "block", background: "red", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }


    function SamplePrevArrow1(props) {
        const { style, onClick } = props;
        return (
            <div
                className="Slider_top_custom_arrows-about-prev"
                // style={{ ...style, display: "block", background: "green", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     nextArrow: <SampleNextArrow />,
    //     prevArrow: <SamplePrevArrow />,

    //     appendDots: dots => (
    //         <div
    //             style={{
    //                 // backgroundColor: "black",
    //                 borderRadius: "10px",
    //                 color: "white",
    //                 padding: "10px"
    //             }}
    //         >
    //             <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
    //         </div>
    //     ),
    // };


    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow1 />,
        prevArrow: <SamplePrevArrow1 />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
        ]
    };


    return (
        <div className="FeaturedPlans slider pos-res">

            <div className='whatsmenu'>
                <div className='container'>
                    <div className="row">
                        <div className='col-lg-12 text-center'>
                            <p className='menuhead'>Whats your Power (Blood Type)</p>
                            <p className='del'> Delivery Services</p>
                        </div>
                        <div className="box_flx_about_container">
                            <Slider {...settings1}>
                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu2} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Mediterranean</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu1} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Keto</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu3} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Nomad</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu4} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Fusion</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu1} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Keto</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu2} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Mediterranean</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu3} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Nomad</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='box_flx_about_slider'>
                                    <div className='crd_menu'>
                                        <img className='w-100' src={manu4} alt="recepie menu" />
                                        <p className='menu_crd_hd'>Fusion</p>
                                        <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                        <p className='menu_dr-p'>2 Beverages</p>
                                        <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                        <div className='d-flex res-bt-dv'>
                                            <button className='btn btn-light mr-3 bt-brwn-brdrrd1 round-0'>View Plan</button>
                                            <button className='btn btn-light bt-light-brdrrd1 round-0'>View Recipe</button>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* <Slider {...settings} className="slider_top_home">
                    {data?.map((item, index) => {
                        return (
                            <div className='col-lg-4 my-5'>
                            <div className='crd_menu'>
                                <img className='w-100' src={manu1} alt="recepie menu" />
                                <p className='menu_crd_hd'>Keto</p>
                                <p className='menu_dr-p'>4 Lunches, 4 Dinners, 2 Grains, 2 Soups</p>
                                <p className='menu_dr-p'>2 Beverages</p>
                                <p className='menu_dr-p2'>$ 8.99 <span>$ 10.00</span></p>
                                <div className='d-flex'>
                                    <button className='btn btn-light mr-3 bt-brwn-brdrrd1'>View Plan</button>
                                    <button className='btn btn-light bt-light-brdrrd1'>View Recipe</button>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </Slider> */}
            </div>
        </div>
    )
}

export default FeaturedPlans;